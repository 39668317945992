.rewards_wrap {
    background: no-repeat scroll top left/cover;
    position: relative;
    padding: 155px 0 95px;
    z-index: 10;
    overflow: hidden;
}

.rewards_wrap .bg_text {
    position: absolute;
    top: 85px;
    left: 270px;
}

.rewards_wrap:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5) 50%, transparent 50%);
}

.rewards_wrap .rewards_left .rewards_title {
    color: #fff;
    font-size: 90px;
    font-weight: 600;
    font-family: "Kumbh Sans", sans-serif;
    letter-spacing: 2px;
    display: inline-block;
    position: relative;
    margin-bottom: 60px;
    max-width: 330px;
    width: 100%;
}

.rewards_wrap .rewards_left .rewards_title:before {
    position: absolute;
    content: "";
    bottom: 20px;
    right: -106px;
    width: 50px;
    height: 6px;
    background: #ffffff;
}

/*slider*/

.equal {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
}

.rewards_wrap .rewards_slider .img_wrap {
    float: left;
}

.rewards_wrap .rewards_slider .description_wrap {
    float: left;
}

.rewards_wrap .rewards_slider .description_wrap .title {
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 30px;
    line-height: 44px;
    margin-bottom: 25px;
}

.rewards_wrap .rewards_slider .description_wrap .description {
    color: #c1c1c1;
    font-size: 16px;
    line-height: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    margin-bottom: 30px;
}

.rewards_wrap .rewards_slider .description_wrap p.country {
    color: #fff;
    font-size: 14px;
    line-height: 26px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    display: inline;
    margin-right: 10px;
    white-space: nowrap;
}

.rewards_wrap .rewards_slider .description_wrap .year {
    color: #c1c1c1;
    font-size: 14px;
    line-height: 26px;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
}
