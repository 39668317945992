.page_rewards {
    padding: 60px 0 65px;
    color: #333333;
    font-weight: 600;
    font-family: "Kumbh Sans", sans-serif;
}

.page_rewards h2 {
    font-size: 35px;
    line-height: 80px;
}

.page_rewards h3 {
    font-size: 20px;
    line-height: 30px;
}

.page_rewards p.country {
    color: #171819;
    font-size: 16px;
    line-height: 5px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    margin: 20px 0;
}

.page_rewards .row {
    padding-bottom: 42px;
    border-bottom: 1px solid #e6e9f1;
}
