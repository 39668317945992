.credentials_wrap {
    background: #171819;
    position: relative;
    padding-bottom: 95px;
    padding-top: 173px;
    z-index: 10;
    overflow: hidden;
}

.credentials_wrap .bg_text {
    position: absolute;
    left: 255px;
    top: 60px;
}

.credentials_wrap .credentials_title {
    color: #999999;
    font-size: 90px;
    font-weight: 600;
    font-family: "Kumbh Sans", sans-serif;
    letter-spacing: 1px;
    display: inline-block;
    position: relative;
    margin-bottom: 68px;
}

.credentials_wrap .credentials_title:before {
    position: absolute;
    content: "";
    bottom: 20px;
    right: -60px;
    width: 50px;
    height: 6px;
    background: #999;
}

.credentials_wrap .credentials_tab {
    border: none;
    margin: 0;
}

.credentials_wrap .credentials_tab li {
    display: block;
    border: none;
    width: 100%;
}

.credentials_wrap .credentials_tab li a {
    color: #666666;
    font-size: 35px;
    line-height: 60px;
    font-weight: 600;
    font-family: "Kumbh Sans", sans-serif;
    border: none;
    padding: 0;
    display: inline-block;
}

.credentials_wrap .credentials_tab li a.active,
.credentials_wrap .credentials_tab li a:hover {
    color: #fff;
    background: none;
    border: none;
}

.credentials_wrap .credentials_tab_content .tab-pane {
    color: #848892;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 72px;
    padding-right: 15px;
    padding-top: 27px;
}

.credentials_wrap .credentials_tab_content .tab-pane p {
    color: #848892;
}

.credentials_wrap .credentials_tab_content .tab-pane > ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.credentials_wrap .credentials_tab_content .tab-pane > ul > li > span {
    font-weight: bold;
    color: #fff;
    margin-right: 20px;
}

.credentials_wrap .credentials_tab_content .tab-pane > ul > li > a {
    text-decoration: underline;
}

.credentials_wrap .credentials_tab_content .tab-pane > ul > li > a:hover {
    color: #fff;
}
